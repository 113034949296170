function CharBox(props) {
  return (
    <div className="char-grid">
      {props.data.map((char) => (
        <div key={char.kr_name} className={`char-box`}>
          <div className="char-info">
            <div className="char-name">
              <div id="char-kr-name">{char.kr_name}</div>
              <div>({char.eng_name})</div>
            </div>
            <div className="char-pos" id={char.eng_pos}>
              {char.kr_pos}
            </div>
          </div>

          <img
            id={char.eng_name.replace(/ /g, "")}
            className={char.eng_pos}
            src={"/char/" + char.index + ".png"}
            alt={char.kr_name + "이미지"}
          />
          <p className="char-desc">{char.desc}</p>
        </div>
      ))}
    </div>
  );
}

export default CharBox;
