import React from "react";

function Navbar() {
  return (
    <div className='main-layout'>
      <img src="logoimg.png" />
    </div>
  );
}

export default Navbar;
