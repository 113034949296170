import React from "react";
import CharLayout from "../components/CharLayout";
import Navbar from "../components/Navbar";

function Home() {
  return (
    <>
      <Navbar />
      <CharLayout />
    </>
  );
}

export default Home;
