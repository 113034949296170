import React, { useState } from "react";
import charData from "../assets/goose.json";
import CharBox from "./CharBox";

function CharLayout() {
  const [query, setQuery] = useState("");

  return (
    <div className="main-layout">
      <input
        className="char-search"
        type="text"
        placeholder="직업 검색 . . ."
        onChange={(e) => setQuery(e.target.value)}
      />

      <CharBox
        data={charData.filter(
          (char) =>
            char.kr_name.includes(query) ||
            char.eng_name.toLowerCase().includes(query)
        )}
      />
    </div>
  );
}

export default CharLayout;
